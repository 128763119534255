import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const ButtonSearch = styled.button`
  color: white;
  border: none;
  background: transparent;
  margin: 0;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    margin: 1rem;
  }
`;

export default function withSearch(Component) {
  const query = graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `;
  const render = (data) => {
    const [openSearchOverlay, setOpenSearchOverlay] = useState(false);
    const globalKeyshort = (e) => {
      if (e.keyCode === 70 && e.ctrlKey && e.shiftKey) {
        setOpenSearchOverlay(true);
      } else if (e.keyCode === 27) {
        setOpenSearchOverlay(false);
      }
    };
    useEffect(() => {
      if (document) {
        document.addEventListener('keydown', globalKeyshort);
        return () => {
          document.removeEventListener('keydown', globalKeyshort);
        };
      }
    }, []);

    return (
      <>
        <Component
          searchIndex={data.localSearchPages.index}
          searchStore={data.localSearchPages.store}
          open={openSearchOverlay}
          onClose={setOpenSearchOverlay}
        />
      </>
    );
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
