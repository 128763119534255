/** Este arquivos existem para evitar um preload de página do gatsby, servem como paginas intermediarias */

import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Loader from '../components/loader';
import SEO from '../components/seo';
import Button from '../components/button';
import { useFlexSearch } from '../components/useFlexSearch';
import withSearch from '../data/search';

const Div = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  margin-bottom: 3rem;
`;

const Input = styled.input`
  font-size: calc(6.4px + 3vw);
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.2rem ${(props) => props.theme.colors.primary} solid;
`;

const SHOW_LIST_MIN = 30;

function SearchPage({ searchIndex, searchStore }) {
  const queryInitialValue = '';
  const [searchListShow, setSearchListShow] = useState(SHOW_LIST_MIN);
  const [query, setQuery] = useState(queryInitialValue);
  let results = useFlexSearch(query, searchIndex, JSON.parse(searchStore), {
    tokenize: 'full',
    encode: 'simple',
    async: false,
  });
  const { length } = results;
  results = results.filter((res) => res).slice(0, searchListShow);

  return (
    <>
      <SEO title="Pesquisa" />
      <Div>
        <InputWrapper>
          <p name="search">Pesquisa</p>
          <Input
            onChange={(e) => setQuery(e.currentTarget.value)}
            ariaDescribedby="search"
          />
        </InputWrapper>
        <ul>
          {results.map((page) => (
            <li key={page.id}>
              <Link to={`/${page.slug}`}>{page.title}</Link>
            </li>
          ))}
        </ul>
        {!!results.length && (
          <Button
            onClick={() => {
              const sum = searchListShow + SHOW_LIST_MIN;
              if (sum <= length) {
                setSearchListShow(sum);
              } else {
                setSearchListShow(length);
              }
            }}
            style={{ maxWidth: '10rem', margin: 'auto' }}
          >
            Mostrar mais
          </Button>
        )}
      </Div>
    </>
  );
}

const Pesquisa = withSearch(SearchPage);

export default Pesquisa;
